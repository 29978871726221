import React, { useState } from "react";
import PropTypes from "prop-types";
import MenuItem, { menuItemType } from "./MenuItem";
import Icon from "../shared/Icon";

export const menuParentProps = {
  autoExpand: PropTypes.boolean,
  children: PropTypes.arrayOf(PropTypes.shape(menuItemType)),
  iconClass: PropTypes.string,
  iconUrl: PropTypes.string,
  label: PropTypes.string.isRequired,
};

const defaultProps = {
  autoExpand: false,
  children: [],
  iconUrl: "",
  iconClass: "",
};

const MenuParent = ({ autoExpand, children, iconUrl, iconClass, label }) => {
  const [showChildren, setShowChildren] = useState(autoExpand);

  const toggleExpand = () => {
    setShowChildren((show) => !show);
  };

  return (
    <div className="nav-bar--parent">
      <div
        className="nav-bar--parent_item"
        onClick={toggleExpand}
        onKeyPress={toggleExpand}
        role="menu"
        tabIndex={0}
      >
        {iconClass ? (
          <Icon name={iconClass} size="sm" className="nav-bar--parent_icon" />
        ) : (
          <img src={iconUrl} alt={label} className="nav-bar--parent_icon-img" />
        )}
        <span>{label}</span>
        <Icon name={showChildren ? "up" : "down"} size="xs" />
      </div>
      <div className="nav-bar--parent_children">
        {showChildren &&
          children.map((child) => {
            return <MenuItem key={label} label={child.label} url={child.url} />;
          })}
      </div>
    </div>
  );
};

MenuParent.propTypes = menuParentProps;
MenuParent.defaultProps = defaultProps;

export default MenuParent;
